import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { RlasManagementGuard } from './rlas-api';
import { RlasMappingGuard } from './rlas-api/rlas-mapping.guard';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    canActivate: [MsalGuard],
    path: 'auth',
    children: [
      {
        path: '',
        redirectTo: 'la',
        pathMatch: 'full'
      },
      {
        path: 'la',
        canActivate: [RlasManagementGuard],
        loadChildren: () => import('./authority/authority.module').then(m => m.AuthorityModule)
      },
      {
        path: 'mapping',
        canActivate: [RlasMappingGuard],
        loadChildren: () => import('./mapping/mapping.module').then(m => m.MappingModule)
      }
    ]
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent
  },
  {
    path: 'unauthorised/:page',
    component: UnauthorisedComponent
  }
];


/**
 * Module for App Routing.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
