import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityGroup } from './model';
import { RlasApiModule } from './rlas-api.module';
import { UserService } from './user.service';

/**
 * Injectable for controlling access to RLAS Mapping pages.
 */
@Injectable({
  providedIn: RlasApiModule
})
export
  class RlasMappingGuardService {

  /**
   * Creates an instance of RLAS Mapping Guard service.
   *
   * @param router The router.
   * @param userService The user service.
   */
  constructor(private router: Router,
    private userService: UserService

  ) { }

  /**
   * Determine if the route can be activate.
   * 
   * @param next The {@link ActivatedRouteSnapshot}.
   * @param state The {@link RouterStateSnapshot}.
   * @returns Emits an observable that indicates if route can be activated.
   */
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {
    return this.userService.me$.pipe(
      map(user => user.groups.includes(SecurityGroup.rlasMappingAccess) || this.router.createUrlTree(['/unauthorised', 'Sherlock']))
    );
  }
}

export const RlasMappingGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(RlasMappingGuardService).canActivate(next, state);
};
